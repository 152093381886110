import React from "react";
import { createRoot } from "react-dom/client";
import ReactGA from "react-ga";
import { HackleProvider } from "@hackler/react-sdk";

import App from "./shared/App";
import * as serviceWorker from "./serviceWorker";
import hackleManager from "helpers/HackleManager";

ReactGA.initialize("UA-125420657-2", {
  debug: false,
});
ReactGA.pageview(window.location.pathname + window.location.search);

createRoot(document.getElementById("root") as HTMLElement).render(
  <HackleProvider hackleClient={hackleManager.hackleClient}>
    <App />
  </HackleProvider>
);
serviceWorker.unregister();
