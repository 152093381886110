import React, { useContext } from "react";
import { observer } from "mobx-react-lite";

import YesNoDialogStore from "domain/store/dialogs/YesNoDialogStore";
import OkDialogStore from "domain/store/dialogs/OkDialogStore";

import YesNoDialog from "presentation/components/common/Modal/YesNoDialog";
import OkDialog from "presentation/components/common/Modal/OkDialog";

function DialogPanel() {
  const yesNoDialogStore = useContext(YesNoDialogStore);
  const okDialogStore = useContext(OkDialogStore);

  return (
    <>
      <YesNoDialog
        title={yesNoDialogStore.title}
        text={yesNoDialogStore.text}
        textAlign={yesNoDialogStore.textAlign}
        yesText={yesNoDialogStore.yesText}
        noText={yesNoDialogStore.noText}
        isOpen={yesNoDialogStore.isOpen}
        onClickYes={yesNoDialogStore.onClickYes}
        onClickNo={yesNoDialogStore.onClickNo}
      />
      <OkDialog
        title={okDialogStore.title}
        text={okDialogStore.text}
        textAlign={okDialogStore.textAlign}
        okText={okDialogStore.okText}
        isOpen={okDialogStore.isOpen}
        onClickOk={okDialogStore.onClickOk}
      />
    </>
  );
}

export default observer(DialogPanel);
