import React from "react";
import styled from "styled-components";

import color from "assets/color";
import VisibleProps from "common/types/VisibleProps";
import zIndex from "common/types/zindexTypes";

type Props = {
  isOpen: boolean;
  title?: string;
  text: string;
  textAlign: string;
  yesText: string;
  noText: string;
  onClickYes: () => void;
  onClickNo: () => void;
};

function YesNoDialog(props: Props) {
  const {
    isOpen,
    title,
    text,
    textAlign,
    yesText,
    noText,
    onClickYes,
    onClickNo,
  } = props;

  return (
    <>
      <Dimmer isVisible={isOpen} />
      <Container isVisible={isOpen}>
        <Dialog>
          <TextBox>
            {title && <Title textAlign={textAlign}>{title}</Title>}
            <Text textAlign={textAlign}>{text}</Text>
          </TextBox>
          <ButtonContainer>
            <NoButton onClick={onClickNo}>{noText}</NoButton>
            <YesButton onClick={onClickYes}>{yesText}</YesButton>
          </ButtonContainer>
        </Dialog>
      </Container>
    </>
  );
}
const Container = styled.div<VisibleProps>`
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${(props: VisibleProps) =>
    props.isVisible ? "translateY(0%)" : "translateY(100%)"};
  transition: transform 0.3s ease-in-out;
  -webkit-transition: transform 0.3s ease-in-out;
  z-index: ${zIndex.layer5};
`;

const Dimmer = styled.div<VisibleProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  display: ${(props: VisibleProps) => (props.isVisible ? "block" : "none")};
  background-color: rgba(0, 0, 0, 0.6);
  z-index: ${zIndex.layer5};
`;

const Dialog = styled.div`
  width: 280px;
  height: auto;
  background: white;
  border-radius: 5px;
  top: 50%;
`;

const TextBox = styled.div`
  width: 100%;
  padding: 20px 20px 16px 20px;
  color: ${color.gray800};
  border-bottom: solid 0.5px #eeeeee;
`;

const Title = styled.h1<{ textAlign: string }>`
  margin-bottom: 16px;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  color: ${color.gray800};
`;

const Text = styled.p<{ textAlign: string }>`
  display: flex;
  width: 100%;
  color: ${color.gray800};
  line-height: 24px;
  align-items: center;
  text-align: center;
  word-break: break-all;
  white-space: pre-line;
`;

const ButtonContainer = styled.div`
  width: 100%;
  height: 52px;
`;

const NoButton = styled.button`
  background: white;
  width: calc(50%);
  height: 100%;
  border-left: none;
  border-right: solid 0.5px #eeeeee;
  border-top: none;
  border-bottom: none;
  border-bottom-left-radius: 5px;
  font-size: 14px;
  color: ${color.gray700};
`;

const YesButton = styled.button`
  background: white;
  width: 50%;
  height: 100%;
  border: none;
  border-bottom-right-radius: 5px;
  font-size: 14px;
  color: ${color.blue400};
`;

export default YesNoDialog;
