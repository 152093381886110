import React, { useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import styled from "styled-components";

import { env } from "helpers/config";
import hackleManager from "helpers/HackleManager";
import AccountManager from "helpers/AccountManager";
import DialogPanel from "./DialogPanel";

import NoticeListPage from "presentation/pages/Notice/NoticeListPage";
import NoticeDetailPage from "presentation/pages/Notice/NoticeDetailPage";
import EVPostPage from "presentation/pages/Partnership/EVPostPage";
import HyundaiOilBankPage from "presentation/pages/Partnership/HyundaiOilBank/HyundaiOilBankPage";
import AttendPage from "presentation/pages/Partnership/HyundaiOilBank/AttendPage";
import TrackingPage from "presentation/pages/Notice/TrackingPage";

import { GlobalStyle } from "./GlobalStyle";

function App() {
  useEffect(() => {
    const setInit = async () => {
      const uid = await AccountManager.getUid();
      hackleManager.setUserId(uid);
      console.log(uid);
    };

    setTimeout(
      () => {
        setInit();
      },
      env.TEST_MODE === "true" ? 0 : 800
    );
  }, []);

  return (
    <Container>
      <DialogPanel />
      <BrowserRouter>
        <Routes>
          <Route path="/android" element={<NoticeListPage />} />
          <Route path="/android/:id" element={<NoticeDetailPage />} />
          <Route path="/ios" element={<NoticeListPage />} />
          <Route path="/ios/:id" element={<NoticeDetailPage />} />
          <Route path="/evpost" element={<EVPostPage />} />
          <Route path="/hyundaioilbank" element={<HyundaiOilBankPage />} />
          <Route path="/hyundaioilbank/attend" element={<AttendPage />} />
          <Route path="/tracking" element={<TrackingPage />} />
          <Route path="/" element={<HashHandlerPage />} />
        </Routes>
      </BrowserRouter>
      <GlobalStyle />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const HashHandlerPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.hash.indexOf("#") >= 0) {
      navigate(location.hash.replace("#", ""), { replace: true });
    }
  }, [location]);

  return <div></div>;
};

export default App;
