import React from "react";
import styled from "styled-components";

import zIndex from "common/types/zindexTypes";
import ic_close_circle from "assets/icon/ic_close_circle_44.svg";
import color from "assets/color";

interface BottomVotingModalProps {
  question: string;
  answers: {
    id: string;
    text: string;
  }[];
  isOpen: boolean;
  onClose: () => void;
  onClickAnswer: (id: string) => void;
}

const BottomVotingModal = (props: BottomVotingModalProps) => {
  const { question, answers, isOpen, onClose, onClickAnswer } = props;

  return (
    <>
      <Dimmer isOpen={isOpen} />
      <Sheet isOpen={isOpen}>
        <CloseButton src={ic_close_circle} alt={"X"} onClick={onClose} />
        <QuestionText>{question}</QuestionText>
        <AnswersBox>
          {answers.map((answer) => (
            <AnswerButton
              key={`answer-${answer.id}`}
              onClick={() => {
                onClickAnswer(answer.id);
              }}
            >
              {answer.text}
            </AnswerButton>
          ))}
        </AnswersBox>
      </Sheet>
    </>
  );
};

const Dimmer = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  z-index: ${zIndex.layer5};
`;

const Sheet = styled.div<{ isOpen: boolean }>`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 0;
  width: 100%;
  height: 26rem;
  border-radius: 1rem 1rem 0 0;
  background-color: white;
  z-index: ${zIndex.layer5};

  ${(props) =>
    props.isOpen
      ? `transform: translateY(0%); transition: transform 0.5s ease-in-out;`
      : `transform: translateY(100%); transition: transform 0.5s ease;`}
`;

const CloseButton = styled.img`
  position: fixed;
  top: 0.9rem;
  right: 0.9rem;
  width: 4.4rem;
  height: 4.4rem;
`;

const QuestionText = styled.h1`
  color: ${color.gray700};
  font-size: 2.4rem;
  line-height: 130%;
  white-space: pre-line;
  text-align: center;
`;

const AnswersBox = styled.div`
  display: flex;
  width: 100%;
  padding: 2.4rem;
  gap: 0.7rem;
`;

const AnswerButton = styled.button`
  width: 100%;
  height: 6rem;
  background: white;
  border: 1px solid ${color.gray400};
  border-radius: 0.4rem;
  font-size: 1.8rem;
  color: ${color.gray800};

  :active {
    border: none;
    background: ${color.blue400};
    color: white;
  }
`;

export default BottomVotingModal;
