import React, { ReactNode } from "react";
import styled from "styled-components";

import zIndex from "common/types/zindexTypes";

import color from "assets/color";
import ic_backarrow_gray from "assets/icon/ic_caret_back.png";
import { useNavigate } from "react-router-dom";

interface NavigationProps {
  navRef?: React.RefObject<HTMLDivElement>;
  navTitle?: string;
  children?: ReactNode;
  onClickBack?: () => void;
}

const NavigationBar = (props: NavigationProps) => {
  const navigate = useNavigate();
  const { children, navTitle } = props;

  const backButtonClickHandler = () => {
    if (props.onClickBack) {
      props.onClickBack();
    } else {
      navigate(-1);
    }
  };

  return (
    <Bar ref={props.navRef}>
      <BackButton onClick={backButtonClickHandler}>
        <img src={ic_backarrow_gray} alt={"뒤로가기"} />
      </BackButton>
      {navTitle && <p>{navTitle}</p>}
      {children}
    </Bar>
  );
};

const Bar = styled.div`
  position: absolute;
  top: 0;
  z-index: ${zIndex.layer1};

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 5.8rem;
  background: ${color.white};

  > p {
    margin-top: 0.2rem;
    font-size: 1.8rem;
    font-weight: 700;
  }
`;

const BackButton = styled.div`
  position: absolute;
  left: 0;
  padding: 1.1rem;
  display: flex;
  align-items: center;

  > img {
    width: 2.4rem;
    height: 2.4rem;
  }
`;

export default NavigationBar;
