import React, { useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import moment from "moment";
import { observer } from "mobx-react-lite";
import { matchRoutes, useLocation } from "react-router-dom";

import NoticeStore from "domain/store/NoticeStore";
import PlatformType from "domain/enum/PlatformType";
import GlobalStore from "domain/store/GlobalStore";

import NoticeItem from "presentation/components/molecules/ListItem";
import useScrollMove from "common/hooks/useScrollMove";

const routes = [{ path: "/:platform" }];

function NoticeListPage() {
  const location = useLocation();
  const matchedRoutes = matchRoutes(routes, location);

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const { scrollMove } = useScrollMove({
    dom: scrollContainerRef,
    timeout: 10,
  });

  const noticeStore = useContext(NoticeStore);
  const globalStore = useContext(GlobalStore);

  useEffect(() => {
    // 백버튼 클릭을 알리기 위해 store 사용.
    if (globalStore.isAlreadySetBackButtonEvent === false) {
      window.addEventListener("popstate", () => {
        globalStore.isOnBackButtonEvent = true;
      });

      globalStore.isAlreadySetBackButtonEvent = true;
    }

    // 경로에 따른 공지 목록 가져오기
    if (
      noticeStore.notices.length === 0 &&
      matchedRoutes &&
      matchedRoutes.length > 0
    ) {
      const platform = matchedRoutes[0].params.platform;
      noticeStore.find(platform as PlatformType);
    }

    // 스크롤 복구
    scrollMove();
  }, []);

  useEffect(() => {
    if (globalStore.isOnBackButtonEvent) {
      globalStore.isOnBackButtonEvent = false;
    }
  }, [globalStore.isOnBackButtonEvent]);

  return (
    <Container ref={scrollContainerRef}>
      {noticeStore.notices.map((notice) => (
        <NoticeItem
          key={notice.id}
          id={notice.id}
          title={notice.title}
          date={moment(notice.date).format("YYYY년 M월 D일")}
          isNew={false}
        />
      ))}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  overflow-y: scroll;
`;

export default observer(NoticeListPage);
