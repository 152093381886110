class AppCommunicator {
  public async logGaEvent(event: string, data?: any) {
    const win = window as any;

    if (win.oilnow) {
      await win.oilnow.logGaEvent(event);
    }

    if (win.webkit && win.webkit.messageHandlers.logGaEvent) {
      const message = {
        event,
        ...data,
      };
      win.webkit.messageHandlers.logGaEvent.postMessage(message);
    }
  }
}

export default new AppCommunicator();
