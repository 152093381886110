import axios from "axios";
import { env } from "helpers/config";
import PlatformType from "domain/enum/PlatformType";
import Notice from "domain/model/Notice";

class NoticeRepository {
  public async find(platform: PlatformType): Promise<Notice[]> {
    try {
      const platformFlag = platform;
      const res = await axios.get(`${env.API_URL}/notices`, {
        params: {
          platform: platformFlag,
        },
      });
      return res.data.data.notices;
    } catch {
      return [];
    }
  }

  public async getDetail(id: number): Promise<Notice | null> {
    try {
      const res = await axios.get(`${env.API_URL}/notices/${id}`);

      return res.data.data;
    } catch {
      return null;
    }
  }
}

export default new NoticeRepository();
