import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import AccountManager from "helpers/AccountManager";
import hackleManager from "helpers/HackleManager";
import useLocationQuery from "common/hooks/useLocationQuery";
import AppCommunicator from "common/helpers/AppCommunicator";
import FirebaseManager from "common/helpers/FirebaseManager";
import EVPostVotingInfo from "domain/model/EVPostVotingInfo";

import NavigationBar from "presentation/components/common/Navigation/NavigationBar";
import BottomVotingModal from "presentation/components/domain/voting/BottomVotingModal";

import img_evpost_navi from "assets/img/img_evpost_navi.png";

const EVPostPage = () => {
  const query = useLocationQuery();
  const navigate = useNavigate();

  const [path, setPath] = useState("");
  const [votingInfo, setVotingInfo] = useState<EVPostVotingInfo>({
    id: "",
    question: "",
    answers: [],
  });
  const [isOpenBottomModal, setIsOpenBottomModal] = useState(false);

  useEffect(() => {
    initEVPostPath();
  }, [query]);

  const initEVPostPath = async () => {
    const path = query.get("path");
    setPath(path ? path : "");

    if (path) {
      setVotingInfo(
        await FirebaseManager.getEvpostVotingInfo(path.replace("wp/", ""))
      );
    }
  };

  const handleClickBack = async () => {
    if (checkIsDoneVoting() === false && votingInfo.id !== "") {
      hackleManager.track("view voting modal", {
        id: votingInfo.id,
      });

      setIsOpenBottomModal(true);
    } else {
      moveToBack();
    }
  };

  const checkIsDoneVoting = () => {
    const uid = AccountManager.getUid();
    const votingRes = localStorage.getItem(`voting-${uid}-${path}`);
    return votingRes ? true : false;
  };

  const handleClickAnswer = (id: string) => {
    completeAnswer(id);
  };

  const handleCloseModal = () => {
    completeAnswer("close");
  };

  const completeAnswer = async (answer: string) => {
    const uid = AccountManager.getUid();
    localStorage.setItem(`voting-${uid}-${path}`, answer);

    if (answer === "close") {
      hackleManager.track("close voting modal", {
        id: votingInfo.id,
      });
    } else {
      hackleManager.track("click voting modal", {
        id: votingInfo.id,
        answer,
      });
    }

    setIsOpenBottomModal(false);
    moveToBack();
  };

  const moveToBack = () => {
    const from = query.get("from");

    if (from === "main") {
      const res = AppCommunicator.goBack();
      if (res) return;
    }

    navigate(-1);
  };

  return (
    <Container>
      <NavigationBar onClickBack={handleClickBack}>
        <Logo src={img_evpost_navi} alt={"오일나우 X EV Post"} />
      </NavigationBar>
      <BottomVotingModal
        question={votingInfo.question}
        answers={votingInfo.answers}
        isOpen={isOpenBottomModal}
        onClose={handleCloseModal}
        onClickAnswer={handleClickAnswer}
      />
      <Iframe src={`https://oilnow.evpost.co.kr/${path}`} />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Iframe = styled.iframe`
  position: absolute;
  top: -5.8rem;
  width: 100%;
  height: calc(100% + 5.8rem);
  border: none;
  overflow: hidden;
`;

const Logo = styled.img`
  height: 2rem;
`;

export default EVPostPage;
