import EVPostVotingInfo from "domain/model/EVPostVotingInfo";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, get, child } from "firebase/database";

import { env } from "helpers/config";

const firebaseConfig = {
  apiKey: env.FIREBASE_API_KEY,
  authDomain: env.FIREBASE_AUTH_DOMAIN,
  projectId: env.FIREBASE_PROJECT_ID,
  databaseURL: env.FIREBASE_DATABASE_URL,
  storageBucket: env.FIREBASE_STORAGE_BUCKET,
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

class FirebaseManager {
  async getEvpostVotingInfo(id: string): Promise<EVPostVotingInfo> {
    try {
      const data = await get(child(ref(db), `evpost/items/${id}`));

      const info = data.val();
      info.question = info.question.replaceAll(/\\n/g, "\n");

      return info;
    } catch {
      return {
        id: "",
        question: "",
        answers: [],
      };
    }
  }
}

export default new FirebaseManager();
