import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import NoticeStore from "domain/store/NoticeStore";
import AppCommunicator from "helpers/AppCommunicator";
import { useLocation, matchRoutes, useNavigate } from "react-router-dom";

const routes = [{ path: "/:platform/:id" }];

function NoticeDetailPage() {
  const location = useLocation();
  const matchedRoutes = matchRoutes(routes, location);
  const navigate = useNavigate();

  const noticeDetailPageRef = useRef<HTMLDivElement>(null);
  const noticeStore = useContext(NoticeStore);

  const [scrollPercentage, setScrollPercentage] = useState(0);

  useEffect(() => {
    const noticeDetailPageElement = noticeDetailPageRef.current;

    if (noticeDetailPageElement !== null) {
      noticeDetailPageElement.addEventListener("scroll", reportScroll);
    }

    if (matchedRoutes && matchedRoutes.length > 0) {
      const id = matchedRoutes[0].params.id;
      noticeStore.select(parseInt(id ? id : "0"));

      // TODO: 현대오일뱅크 프로모션을 위한 하드코딩 로직
      if (id === "23050001") {
        navigate("/hyundaioilbank", { replace: true });
      }
    }
  }, []);

  useEffect(() => {
    if (noticeStore.selectedNotice) {
      const noticeId = noticeStore.selectedNotice.id;
      AppCommunicator.logGaEvent(`check_notice_${noticeId}`);
    }
  }, [noticeStore.selectedNotice]);

  useEffect(() => {
    const noticeDetailPageElement = noticeDetailPageRef.current;

    if (scrollPercentage >= 90 && noticeStore.selectedNotice) {
      const noticeId = noticeStore.selectedNotice.id;
      AppCommunicator.logGaEvent(`complete_to_read_notice_${noticeId}`);
    }

    if (noticeDetailPageElement !== null && scrollPercentage >= 90) {
      noticeDetailPageElement.removeEventListener("scroll", reportScroll);
    }
  }, [scrollPercentage]);

  const reportScroll = useCallback(() => {
    const noticeDetailPageElement = noticeDetailPageRef.current;

    if (noticeDetailPageElement !== null) {
      const height =
        noticeDetailPageElement.scrollHeight -
        noticeDetailPageElement.clientHeight;
      const currentScrollPercentage =
        (noticeDetailPageElement.scrollTop / height) * 100;
      setScrollPercentage(currentScrollPercentage);
    }
  }, []);

  return (
    <Container ref={noticeDetailPageRef}>
      {noticeStore.selectedNotice ? (
        <div
          dangerouslySetInnerHTML={{ __html: noticeStore.selectedNotice.html }}
        />
      ) : null}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`;

export default observer(NoticeDetailPage);
