import queryString from "query-string";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import hackleManager from "helpers/HackleManager";

function TrackingPage() {
  const location = useLocation();
  const params = queryString.parse(location.search);

  useEffect(() => {
    const win = window as any;

    if (params.code) {
      hackleManager.track(params.code as string);
      win.location.replace(params.url as string);
    } else {
      win.location.replace("https://www.oilnow.co.kr");
    }
  }, []);

  return null;
}

export default TrackingPage;
