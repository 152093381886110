import { createContext } from "react";
import { makeAutoObservable } from "mobx";

import Notice from "domain/model/Notice";
import NoticeRepository from "domain/repository/NoticeRepository";
import PlatformType from "domain/enum/PlatformType";

class NoticeStore {
  notices = [] as Notice[];
  selectedNotice = null as Notice | null;

  constructor() {
    makeAutoObservable(this);
  }

  find = async (platform: PlatformType) => {
    this.notices = await NoticeRepository.find(platform);
  };

  select = async (id: number) => {
    const notice = this.notices.find((notice) => `${notice.id}` === `${id}`);

    if (notice) {
      this.selectedNotice = notice;
    } else {
      this.selectedNotice = await NoticeRepository.getDetail(id);
    }
  };
}

export default createContext(new NoticeStore());
